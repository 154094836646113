<template>
  <div :class="$style.verifyOrg">
    <el-dialog
      :visible.sync="visible"
      :title="'经理信息'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div :class="$style.flexBox">
        <div><font>姓名:</font>{{ this.allData.name }}</div>
        <div><font>企业码:</font>{{ this.allData.orgIdentify }}</div>
        <div><font>身份证号:</font>{{ this.allData.certificateNo }}</div>
        <div>
          <font>状态:</font>
          <el-tag size="small">{{
            computedStatus(this.allData.orgManagerStatus)
          }}</el-tag>
        </div>
        <div><font>申请时间:</font>{{ this.allData.createDate }}</div>
      </div>
      <div style="margin: 20px 0 0">
        <el-image
          v-for="(item, index) in fileList"
          :key="index"
          style="width: 100px; height: 100px; margin: 0 20px"
          :src="item"
          :preview-src-list="fileList"
          :z-index="9999"
        >
        </el-image>
      </div>
      <el-input
        type="textarea"
        :rows="2"
        placeholder="请输入审核意见"
        v-model="auditContent"
        style="margin: 20px 0 0; heigh: 100px"
        :autosize="{ minRows: 3, maxRows: 4 }"
      >
      </el-input>
      <template slot="footer">
        <el-button
          type="primary"
          @click="confirm('Y')"
          :disabled="this.allData.orgManagerStatus !== 2"
          >审核通过</el-button
        >
        <el-button
          @click="confirm('N')"
          :disabled="this.allData.orgManagerStatus !== 2"
          >审核不通过</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
  data() {
    return {
      auditContent: '',
      visible: false,
      fileList: [],
      dataForm: {
        id: '',
      },
      allData: {},
    };
  },
  computed: {},
  methods: {
    confirm(flag) {
      if (flag == 'N' && !this.auditContent) {
        this.$notify({
          title: '错误',
          message: '请输入审核意见',
          type: 'error',
        });
        return;
      }
      this.$http
        .post('/orgmanager/audit', {
          id: this.dataForm.id,
          auditFlag: flag,
          auditContent: this.auditContent,
        })
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.fileList = res.data.data.fileList;
            this.allData = res.data.data;
            console.log(this.fileList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.$bus.emit('orgc_list_refresh');
      Object.assign(this.$data, this.$options.data());
    },
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.query();
      });
    },
    query() {
      this.$http
        .get('/orgmanager/info', { params: { id: this.dataForm.id } })
        .then((res) => {
          console.log(res);
          if (res.data.code === 0) {
            this.fileList = res.data.data.fileList;
            this.allData = res.data.data;
            console.log(this.fileList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    computedStatus(id) {
      switch (~~id) {
        case 1:
          return '已认证';
        case 2:
          return '已提交审核中';
        default:
          return '认证不通过';
      }
    },
  },
};
</script>

<style lang="scss" module scope="this api replaced by slot-scope in 2.5.0+">
.verifyOrg {
  :global {
    .mod-sys__dept {
      .dept-list {
        .el-input__inner,
        .el-input__suffix {
          cursor: pointer;
        }
      }
    }
    .el-descriptions__table,
    .el-descriptions--default {
      width: 100%;
    }
    .el-descriptions-row {
      margin-top: 5px;
    }
    .el-descriptions-item__label {
      margin-right: 10px;
    }
  }
  .flexBox {
    & > div {
      display: inline-block;
      width: 33%;
      margin: 10px 0;
    }
    font {
      margin-right: 20px;
    }
  }
}
</style>
