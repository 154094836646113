<template>
    <el-card shadow="never" class="aui-card--fill">
        <div>
            <el-form
                :inline="true"
                :model="dataForm"
                @keyup.enter.native="getDataList()"
            >
                <el-form-item>
                    <el-input
                        v-model="dataForm.orgIdentify"
                        :placeholder="'企业码'"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="dataForm.name"
                        :placeholder="'姓名'"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="dataForm.certificateNo"
                        :placeholder="'身份证号'"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="dataForm.orgManagerStatus"
                        :placeholder="'审核状态'"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-date-picker
                        v-model="dataForm.startDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="'申请开始时间'"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="dataForm.endDate"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :placeholder="'申请结束时间'"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button @click="getDataList()">{{
                        $t('query')
                    }}</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="resetSearch()">{{
                        $t('cardList.reset')
                    }}</el-button>
                </el-form-item>
            </el-form>
            <el-table
                v-loading="dataListLoading"
                :data="dataList"
                border
                @selection-change="dataListSelectionChangeHandle"
                @sort-change="dataListSortChangeHandle"
                style="width: 100%"
            >
                <el-table-column
                    prop="name"
                    :label="'姓名'"
                    header-align="center"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="orgIdentify"
                    :label="'企业码'"
                    header-align="center"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="certificateNo"
                    :label="'身份证号'"
                    header-align="center"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    prop="orgManagerStatus"
                    :label="'状态'"
                    header-align="center"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span>{{
                            computedStatus(scope.row.orgManagerStatus)
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="createDate"
                    :label="'申请时间'"
                    header-align="center"
                    align="center"
                >
                </el-table-column>

                <el-table-column
                    :label="'操作'"
                    fixed="right"
                    header-align="center"
                    align="center"
                    width="250"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="showDetailFuc(scope.row)"
                            >详情</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page="page"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="limit"
                :total="total"
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="pageSizeChangeHandle"
                @current-change="pageCurrentChangeHandle"
            >
            </el-pagination>
            <verify-org
                v-if="showDetail"
                ref="showCardDetail"
                @refreshDataList="getDataList"
            ></verify-org>
        </div>
    </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
import verifyOrg from './verifyOrg';
const apiUrls = {
    page: '/orgmanager/page',
};
export default {
    mixins: [mixinViewModule],
    components: {
        verifyOrg,
    },
    data() {
        return {
            mixinViewModuleOptions: {
                getDataListURL: apiUrls.page,
                getDataListIsPage: true,
                // exportURL: '/health/cardbatch/export',
            },
            //  0：未激活 1：已激活
            options: [
                {
                    value: '1',
                    label: '已认证',
                },
                {
                    value: '2',
                    label: '认证中',
                },
                {
                    value: '3',
                    label: '认证不通过',
                },
                // {
                //     value: '',
                //     label: '所有状态',
                // },
            ],
            dataForm: {
                orgIdentify: '', // 企业码
                name: '',
                certificateNo: '',
                orgManagerStatus: '', // 审核状态 1：已认证 2：认证中 3：认证不通过
                startDate: '', // 开始时间
                endDate: '', // 结束时间
            },
            statusArray: [
                {
                    value: 0,
                    text: this.$t('cardList.noActive'),
                },
                {
                    value: 1,
                    text: this.$t('cardList.actived'),
                },
            ],
            showDetail: false,
        };
    },
    methods: {
        showDetailFuc(row) {
            this.showDetail = true;
            this.$nextTick(() => {
                console.log(this.$refs.showCardDetail.dataForm.id, row.id);
                this.$refs.showCardDetail.dataForm.id = row.id;
                this.$refs.showCardDetail.init();
            });
        },
        computedStatus(id) {
            switch (~~id) {
                case 1:
                    return '已认证';
                case 2:
                    return '已提交审核中';
                default:
                    return '提交认证';
            }
        },
        async resend(row) {
            let params = {
                id: row.id,
            };
            const { data: res } = await this.$http.get('/health/card/resend', {
                params,
            });
            const { data, code, msg } = res;
            if (code === 0) {
                this.$notify({
                    title: '提示',
                    message: '短信重发成功',
                    type: 'info',
                    duration: 5000,
                    type: 'success',
                });
            } else {
                this.$notify.error({
                    title: '提示',
                    message: msg,
                    type: 'info',
                    duration: 5000,
                });
            }
        },
        statusHandler(data) {
            const demo = this.options.filter((i) => i.value === String(data));
            return (demo.length && demo[0].label) || '';
        },
        resetSearch() {
            this.dataForm = {
                orgIdentify: '', // 企业码
                name: '',
                certificateNo: '',
                orgManagerStatus: '', // 审核状态 1：已认证 2：认证中 3：认证不通过
                startDate: '', // 开始时间
                endDate: '', // 结束时间
            };
        },

        filterStatus(value, row) {
            return row.status == value;
        },
    },

    created() {
        this.$bus.on('orgc_list_refresh', this.getDataList);
    },
};
</script>
