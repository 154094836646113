import { render, staticRenderFns } from "./verifyOrg.vue?vue&type=template&id=ef95321e"
import script from "./verifyOrg.vue?vue&type=script&lang=js"
export * from "./verifyOrg.vue?vue&type=script&lang=js"
import style0 from "./verifyOrg.vue?vue&type=style&index=0&id=ef95321e&prod&lang=scss&module=true&scope=this%20api%20replaced%20by%20slot-scope%20in%202.5.0%2B"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports